
class Duplicator{


    constructor(dom){

        this.dom=dom;
        this.max_items=this.dom.children.length;
        this.offset=100;
        this.DEBUG=false;
        this.MOVED=0;
        window.addEventListener("scroll",function(e){

            var first=(this.dom.children[0]);
          
            var last=(this.dom.children[this.dom.children.length-1]);
           
             var diff=last.getBoundingClientRect().bottom-window.innerHeight;
            if(diff<this.offset){
                //move first to last
                this.firstToLast();
            }else{
                var diff_first=first.getBoundingClientRect().top;
               
                if(diff_first>0){
               
              
                this.lastToFirst();
             
             }




            }


        }.bind(this));
    }
    resetToTop(){
        this.MOVED=0;
        window.scrollTo(0,0);
        for(var i=0;i<this.dom.children.length;i++){

            var item=this.dom.querySelector("[data-index='"+i+"']");
           
           item.setAttribute("data-order",i);

           
        }
        this.positionToMoved();




    }
    lastToFirst(){
        if(this.DEBUG){

          //  return;
        }
        this.DEBUG=true;

      
      //  var cur_scroll=document.documentElement.scrollTop || document.body.scrollTop;

     
      /*  var last=this.dom.children[this.dom.children.length-1];
        var first=this.dom.children[0];
        last.parentNode.insertBefore(last, last);*/

        this.MOVED--;
       

      
        this.positionToMoved();
     
      

    }
    positionToMoved(){
       
      

      

        

        this.dom.setAttribute("data-moved",this.MOVED);
        var whole=Math.floor(this.MOVED/this.dom.children.length);
         var left_items=(this.MOVED % this.dom.children.length);
        
        //[0,1,2,3]
            var start_index=(left_items);
            var total_height=0;
        for(var i=0;i<this.dom.children.length;i++){

            var item=this.dom.querySelector("[data-index='"+start_index+"']");
            total_height+=this.dom.children[i].getBoundingClientRect().height;
            start_index++;
            if(start_index>this.dom.children.length-1){
                start_index=0;
            }
           item.setAttribute("data-order",i);

           
        }
        var h=300;//tem

    

        var padding=total_height*whole;
       
       
       
        for(var i=0;i<left_items;i++){
            
            padding+= this.dom.querySelector("[data-index='"+i+"']").getBoundingClientRect().height;

        }




        this.dom.style.paddingTop=padding+"px";

       
        /*const frag = document.createDocumentFragment();
        const list =this.dom;
        const items =this.dom.children;
        const sortedList = Array.from(items).sort(function(a, b) {
          const c = parseInt(a.getAttribute("data-order"));
          const d = parseInt(b.getAttribute("data-order"));
          return c < d ? -1 : c > d ? 1 : 0;
        });
        for (let item of sortedList) {
          frag.appendChild(item);
        }
        list.appendChild(frag);*/

        var isOrdered=false;
        var loops=0;
       while(!isOrdered){

        loops++;
        var isOk=true;
        for(var i=0;i<this.dom.children.length-1;i++){
        var aa=this.dom.children[i];
        var bb=this.dom.children[i+1];
          var a=parseInt(aa.getAttribute("data-order"));
          var b=parseInt(bb.getAttribute("data-order"));
         
            if(a>b){
               bb.parentNode.insertBefore(bb, aa);
              
                isOk=false;
            }

        }
        if(isOk){
            isOrdered=true;
        }
        if(loops>100){
            isOrdered=true;
        }
       }

    /*  console.log(this.MOVED);







      
      

        this.dom.setAttribute("data-moved",this.MOVED);
       console.log("START",left_items);
        var c=0;
        var start_index=0;
      
        


       
        var padding=0;
        var total=0;
        for(var i=0;i<this.dom.children.length;i++){
            total+=this.dom.children[i].getBoundingClientRect().height;
          

        }

        padding=total*whole;

        for(var i=0;i<left_items;i++){
         
            padding+= this.dom.children[i].getBoundingClientRect().height;

        }
        this.dom.style.paddingTop=padding+"px";



        */

    }
    firstToLast(){
        LOTTIE_RUNNED=true;
     
        if(this.DEBUG){
          
        }
     
       // var cur_scroll=document.documentElement.scrollTop || document.body.scrollTop;
        this.MOVED++;
        this.positionToMoved();
      /*  var last=this.dom.children[this.dom.children.length-1];
        var first=this.dom.children[0];
       
        last.after(first);
        this.MOVED++;
        this.positionToMoved();
        this.positionToMoved();
        document.documentElement.scrollTop = document.body.scrollTop = cur_scroll;*/
       return;
        var whole=Math.floor(this.MOVED/this.dom.children.length);

        var left_items=(this.MOVED % this.dom.children.length);

        this.dom.setAttribute("data-moved",this.MOVED);
       
        var padding=0;
        var total=0;
        for(var i=0;i<this.dom.children.length;i++){
            total+=this.dom.children[i].getBoundingClientRect().height;
          

        }
      
        padding=total*whole;

        for(var i=0;i<left_items;i++){
      
        padding+= this.dom.children[i].getBoundingClientRect().height;

        }
        this.dom.style.paddingTop=padding+"px";
       /* var last_index= (this.dom.children[this.dom.children.length-1].getAttribute("data-index"));
       
        var padding=0;
        for(var i=0;i<=last_index;i++){

            var h=this.dom.children[i].getBoundingClientRect().height;
            padding+=h;
        }
        console.log(padding);
        this.dom.style.paddingTop=padding+"px";*/
        var cur2=document.documentElement.scrollTop || document.body.scrollTop;
       

        document.documentElement.scrollTop = document.body.scrollTop = cur_scroll;


    }


}